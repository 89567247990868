@import '../styles/palette';

main {
  > *:not(section),
  section > * {
    padding: 3em;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  section {
    flex-grow: 1;
  }
}

@media screen and (max-width: 767px) {
  main {
    > *:not(section),
    section > * {
      padding: 2em 1em;
    }
  }
}

h2 {
  color: $pink;
}

input {
  font-size: 100%;
}
