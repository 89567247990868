@import '../../../styles/palette';

$row-height: 50px;
$label-width: 180px;

.timeline {
  position: relative;
  margin: 4px;

  @media screen and (min-width: 1200px) {
    margin-left: $label-width;
  }
  .timeline-title {
    display: flex;
    justify-content: space-between;
  }

  .timeline-date {
    color: $coral;
  }

  .timeline-hours {
    margin-left: 30px;
    margin-right: 10px;
    color: $grey;
    height: $row-height;
    position: relative;

    .timeline-hour {
      position: absolute;

      .timeline-hour-text {
        margin-left: -5px;
      }
    }
  }

  .timeline-slot-picker {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media screen and (min-width: 990px) {
    .timeline-slot-picker {
      //breakpoint happens at 990, if no width specified, the view is jumpy
      min-width: 380px;
      max-width: 730px;
    }
  }
  .timeline-rows {
    position: relative;
    margin-left: 20px;
    margin-right: 10px;
    .timeline-row {
      height: $row-height;
      display: flex;

      .timeline-row-label {
        display: flex;
        justify-content: flex-end;
        width: $label-width;

        .avatar {
          color: $grey;
          display: flex;
          width: 100%;
          align-items: center;
          padding-right: 20px;

          span {
            flex: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            border-radius: 50%;
            flex: 0.5;
          }
        }
      }

      @media screen and (min-width: 1200px) {
        .timeline-row-label {
          width: $label-width;
          margin-left: -$label-width;
        }
      }

      .timeline-row-nolabel {
        display: flex;
        justify-content: flex-end;
        width: 30px;
        margin-left: -30px;

        .avatar {
          color: $grey;
          display: flex;
          align-items: center;

          img {
            margin-left: 10px;
            border-radius: 50%;
          }
        }
      }

      .timeline-busy {
        position: relative;
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }

    .busy-column {
      background-color: lighten($peach, 12%);
      border: 1px dashed lighten($peach, 8%);
      mix-blend-mode: darken;
      z-index: 0;
      position: absolute;
      top: 0;
      height: 100%;
    }
  }

  .clickable {
    opacity: 0.6;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .slot {
    height: $row-height;
    position: absolute;
    background-color: $peach;
    border: 1px solid darken($peach, 4%);
    z-index: 1;

    &.candidate {
      background-color: $green;
      border: 1px solid darken($green, 4%);
      height: 40px;

      &:hover {
        cursor: pointer;
        background-color: lighten($green, 5%);
      }

      .delete-btn {
        color: $purple;
        position: absolute;
        right: -20px;
        top: -15px;
      }
    }

    &.pending:global(.ui.placeholder) {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(0, 0, 0, 0.08) 15%,
        rgba(0, 0, 0, 0.04) 30%
      );
      background-size: 1200px 100%;
      border: 1px solid darken($light-grey, 4%);
      width: 100%;
      max-width: none;
    }
  }

  .timeline-input-wrapper {
    display: flex;
    margin-left: -5px;
  }

  .timeline-input {
    opacity: 0.6;
    display: flex;
    align-items: center;
    min-height: $row-height;
    background-color: lighten($grey, 45%);
    border-radius: 3px;
    box-sizing: content-box;
    flex-basis: 100%;
    margin-left: 5px;

    .timeline-candidates {
      display: flex;
      flex-direction: column;

      .candidates-group {
        display: flex;
        align-items: center;
        height: $row-height;
      }
    }

    &.msg {
      align-items: center;
      justify-content: center;
      font-style: italic;
      border: 1px solid $grey;

      &:hover {
        opacity: 0.4;
        cursor: pointer;
      }
    }

    &.edit {
      background-color: lighten($green, 27%);
      border: 5px solid lighten($green, 22%);
      margin: -5px -40px;
      padding: 10px;

      .add-btn {
        margin-left: auto;
        position: absolute;
        right: -35px;
        color: $grey;
      }
    }
  }
}

.time-input {
  display: flex !important;
  width: 150px;

  input[type='time']::-webkit-clear-button {
    display: none;
  }
}

.config-box {
  background-color: $coral;
  padding: 0.5em;
  color: $white;
  margin-bottom: 15px;
  border-radius: 3px;

  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :global(.ui.dropdown) {
      margin-left: 0.5em;
    }

    &:not(:last-child) {
      padding-bottom: 0.5em;
    }
  }

  :global(.ui.dropdown) {
    width: 14em;

    :global(.divider.text) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      // keep letters like g and p from being cut off at the bottom
      line-height: calc(1em + 1.5px);
    }
  }
}

.timepicker-popup {
  width: 184px;

  :global(.rc-time-picker) {
    width: 111px !important;
    vertical-align: top;
  }

  :global(.rc-time-picker-input) {
    height: 36px;
    font-family: inherit;
    font-size: 14px;
  }

  :global(.rc-time-picker-input):focus {
    outline: none;
  }

  :global(.rc-time-picker-panel-input),
  :global(.rc-time-picker-panel-select),
  :global(.rc-time-picker-panel-inner) {
    font-family: inherit;
    font-size: 14px;
  }

  :global(.rc-time-picker-panel-input-wrap) {
    // make sure time does not jump when opening the time picker
    padding-left: calc(0.5em - 1px);
    padding-top: calc(0.5em + 1px);
  }

  :global(.ui.icon.button) {
    margin: 0 0 0 0.25em;
  }
}
