@import '../styles/palette';

.title {
  color: $purple;
  display: flex;
  align-items: center;

  .header {
    margin-bottom: 0;
  }

  .label {
    margin-left: 5px;
  }
}

.subtitle {
  display: flex;
  gap: 1em;

  .author {
    color: $coral;
  }
}

.box {
  padding: 15px 1.5em;
  background-color: $white;

  .flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .view-options {
    display: flex;
    align-items: center;
  }

  .navigation {
    padding-right: 6px;
  }

  .shareable-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    align-items: center;
    margin-top: 0.5em;
    font-size: 9pt;
    color: $grey;

    .legend {
      font-weight: 700;
    }
  }
}
