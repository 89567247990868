@import '../styles/palette';

.prev-icon {
  position: absolute;
  top: 14px;
  left: 20px;
  right: 0;
  z-index: 999;
  color: $coral;

  &:hover {
    cursor: pointer;
  }
}

.next-icon {
  position: absolute;
  top: 14px;
  right: -25px;
  z-index: 999;
  color: $coral;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .next-icon {
    position: absolute;
    top: 14px;
    right: 0px;
    z-index: 999;
    color: $coral;

    &:hover {
      cursor: pointer;
    }
  }
}
