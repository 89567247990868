@import '../styles/palette';

.newdles {
  color: $don-juan;

  .newdle {
    background-color: lighten($peach, 10%);
    cursor: pointer;
    margin-bottom: 1.5em;
    padding: 1em;
    min-height: 6.5em;

    &:global(.ui.placeholder) {
      max-width: none;
    }

    &:hover {
      background-color: lighten($peach, 8%);
    }
  }

  .new-newdle {
    border: 2px solid lighten($purple, 50%);
    cursor: pointer;
    margin-bottom: 1.5em;
    padding: 1em 1em 0.2em 1em;
    display: flex;
    justify-content: space-between;

    &:global(.ui.placeholder) {
      max-width: none;
    }

    &:hover {
      background-color: lighten($purple, 63%);
      border: 2px solid lighten($purple, 45%);
    }
  }

  .title {
    align-items: center;
    color: $purple;
    display: flex;
    font-size: 1.3em;

    a,
    a:hover {
      color: $purple;
    }

    :global(.ui.label).state {
      margin-left: 1em;
      padding: 0.5em;
    }
  }

  .info {
    :first-child {
      padding-right: 1em;
    }
  }

  .participants {
    color: lighten($don-juan, 20%);
    padding-top: 0.5em;

    :global(.icon) {
      padding-right: 1.5em;
    }

    :global(label) {
      font-size: 0.8em;
    }
  }
}
